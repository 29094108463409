import React, { PureComponent } from "react";
import { withThreeSkyeGlobal } from "@threeskye/global";
import withRouteChange from "@threeskye/route-change";
import { withRouter } from "react-router-dom";
import ToolTip from "../components/Shared/ToolTip";
import Tooltip from "../components/Popups/Tooltip";


class Link extends PureComponent {
    constructor(props) {
        super(props);

        this.getPath = this.getPath.bind(this);
        this.onClick = this.onClick.bind(this);

        let path = this.getPath();
        let current = this.props.history.location.pathname;

        let active = current.indexOf(path) === 0
            || (current === "/" && this.props.default);

        this.state = {
            active
        };

        this.props.storage.watch("crm.activePath", val => {
            let path = this.getPath();
            this.setState({ active: val.indexOf(path) === 0 })
        });
    }

    getPath() {
        let path = this.props.pathPrefix;
        if (path.indexOf("/") !== 0)
            path = "/" + path;
        return path;
    }

    onClick() {
        let path = this.getPath();
        this.props.changeRoute(path);
        this.props.dismiss();
    }

    render() {
        const i = this.props.i18n.get;
        return (
                <li
                    className={
                        this.state.active
                            ? "active"
                            : ""
                    }
                    onClick={this.onClick}
            >
                <Tooltip active rightCenter sidebarLink label={i(this.props.textKey)} placement="right" delay={500}>
                
                    <i className={`${this.props.icon === 'equalizer' ? 'material-symbols-outlined': 'material-icons'}`}>{this.props.icon}</i>
                    <p>{i(this.props.textKey)}</p>
                </Tooltip>

            </li>
        )
    }
}

export default withRouter(withThreeSkyeGlobal(withRouteChange(Link)));
